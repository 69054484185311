var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-full h-full p-4 pr-8 bg-white rounded-lg flex-no-grow",class:{ 'opacity-80': _vm.disabled },attrs:{"data-card":""}},[(!!_vm.preptoolQuestion.info_translation_key)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeInfo),expression:"closeInfo"}],staticClass:"absolute flex space-x-2 top-2 right-2",on:{"click":_vm.onInfoClick}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.infoOpen)?_c('div',{staticClass:"absolute z-30 p-4 text-xs bg-gray-100 rounded-lg shadow-md right-6 top-4 w-72",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            ("preparation-tool.questions.info." + (this.preptoolQuestion.info_translation_key))
          )
        )}}):_vm._e()]),_c('icon-info',{staticClass:"w-5 h-5"})],1):_vm._e(),_c('div',{staticClass:"flex flex-col justify-between h-full"},[_c('t-button',{staticClass:"absolute grid w-8 h-8 rounded-full shadow-md bottom-4 right-4 place-items-center bg-brand-darkblue",attrs:{"variant":"circle"}},[(_vm.isEditing || !_vm.hasAnswered)?_c('icon-save',{staticClass:"w-4 h-4 text-white fill-current",attrs:{"e":""},on:{"click":_vm.onCheckClick}}):_c('icon-pencil',{staticClass:"w-4 h-4 text-white fill-current",on:{"click":_vm.onEditClick}})],1),_c('h4',{staticClass:"mr-6 font-medium"},[_vm._v(" "+_vm._s(_vm.$t( ("preparation-tool.questions." + (_vm.preptoolQuestion.question_translation_key)) ))+" ")]),(!!_vm.preptoolQuestion.intro)?_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v(" "+_vm._s(_vm.$t(("preparation-tool.questions.intro." + (_vm.preptoolQuestion.intro))))+" ")]):_vm._e(),_c('form',{key:_vm.preptoolQuestion.question_translation_key,staticClass:"mt-4 mr-8"},[(
        _vm.preptoolQuestion.answer_type === 'text' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex space-x-4"},[_c('t-input',{staticClass:"flex-1",attrs:{"type":"text"},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1):(
        _vm.preptoolQuestion.answer_type === 'select' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex space-x-2"},[_c('t-select',{staticClass:"flex-1",attrs:{"options":_vm.preptoolQuestion.answers.map(function (answer) { return ({
            value: _vm.$t(("answers." + (answer.value))),
            text: _vm.$t(("answers." + (answer.text))),
          }); })},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1):(
        _vm.preptoolQuestion.answer_type === 'checkbox' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex space-x-2"},[_c('t-checkbox-group',{staticClass:"flex-1",attrs:{"options":_vm.preptoolQuestion.answers.map(function (answer) { return _vm.$t(("chem." + answer)); })},on:{"change":_vm.handleChange}})],1):(
        _vm.preptoolQuestion.answer_type === 'radio' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex flex-1 space-x-2"},[_c('t-radio-group',{attrs:{"name":_vm.preptoolQuestion.question_translation_key,"options":[
          _vm.$t('answers.yes'),
          _vm.$t('answers.no'),
          _vm.$t('answers.does_not_apply') ]},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1):(
        _vm.preptoolQuestion.answer_type === 'calendar' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex items-start space-x-2"},[(_vm.preptoolQuestion.question_translation_key == 'preptool_question_two')?_c('t-input',{staticClass:"form-input form-date",attrs:{"type":"datetime-local","min":_vm.$moment(_vm.event.date_from).format('YYYY-MM-DD[T]00:00')},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}}):_c('t-input',{staticClass:"form-input form-date",attrs:{"type":"datetime-local"},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1):(
        _vm.preptoolQuestion.answer_type === 'slider' &&
        (_vm.isEditing || !_vm.hasAnswered)
      )?_c('div',{staticClass:"flex items-center space-x-2"},[_c('vue-slider',{staticClass:"flex-1",attrs:{"min":0,"max":10,"data-value":"label","label-style":{ display: 'none' },"data":_vm.preptoolQuestion.answer_values},on:{"change":_vm.handleChange}})],1):_c('div',[(!_vm.answerIsOfTypeArray)?_c('p',[_vm._v(" "+_vm._s(_vm.$moment(_vm.answer).format("DD MMMM YYYY, HH:mm") === "Invalid date" ? _vm.answer : _vm.$moment(_vm.answer).format("DD MMMM YYYY, HH:mm"))+" ")]):_c('ul',_vm._l((_vm.answer),function(a){return _c('li',{key:a,staticClass:"ml-8 list-disc"},[_vm._v(_vm._s(a))])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="relative w-full h-full p-4 pr-8 bg-white rounded-lg flex-no-grow" :class="{ 'opacity-80': disabled }"
    data-card>
    <div class="absolute flex space-x-2 top-2 right-2" @click="onInfoClick" v-click-outside="closeInfo"
      v-if="!!preptoolQuestion.info_translation_key">
      <transition name="fade">
        <div v-if="infoOpen" class="absolute z-30 p-4 text-xs bg-gray-100 rounded-lg shadow-md right-6 top-4 w-72"
          v-html="
            $t(
              `preparation-tool.questions.info.${this.preptoolQuestion.info_translation_key}`
            )
          "></div>
      </transition>

      <icon-info class="w-5 h-5" />
    </div>

    <div class="flex flex-col justify-between h-full">
      <t-button variant="circle"
        class="absolute grid w-8 h-8 rounded-full shadow-md bottom-4 right-4 place-items-center bg-brand-darkblue">
        <icon-save e @click="onCheckClick" v-if="isEditing || !hasAnswered" class="w-4 h-4 text-white fill-current" />
        <icon-pencil @click="onEditClick" v-else class="w-4 h-4 text-white fill-current" />
      </t-button>

      <h4 class="mr-6 font-medium">
        {{
        $t(
        `preparation-tool.questions.${preptoolQuestion.question_translation_key}`
        )
        }}
      </h4>

      <p class="text-xs text-gray-400" v-if="!!preptoolQuestion.intro">
        {{ $t(`preparation-tool.questions.intro.${preptoolQuestion.intro}`) }}
      </p>

      <form class="mt-4 mr-8" :key="preptoolQuestion.question_translation_key">
        <div class="flex space-x-4" v-if="
          preptoolQuestion.answer_type === 'text' &&
          (isEditing || !hasAnswered)
        ">
          <t-input class="flex-1" type="text" v-model="answer" />
        </div>

        <div class="flex space-x-2" v-else-if="
          preptoolQuestion.answer_type === 'select' &&
          (isEditing || !hasAnswered)
        ">
          <t-select class="flex-1" v-model="answer" :options="
            preptoolQuestion.answers.map((answer) => ({
              value: $t(`answers.${answer.value}`),
              text: $t(`answers.${answer.text}`),
            }))
          " />
        </div>

        <div class="flex space-x-2" v-else-if="
          preptoolQuestion.answer_type === 'checkbox' &&
          (isEditing || !hasAnswered)
        ">
          <t-checkbox-group @change="handleChange" class="flex-1" :options="
            preptoolQuestion.answers.map((answer) => $t(`chem.${answer}`))
          " />
        </div>

        <div class="flex flex-1 space-x-2" v-else-if="
          preptoolQuestion.answer_type === 'radio' &&
          (isEditing || !hasAnswered)
        ">
          <t-radio-group :name="preptoolQuestion.question_translation_key" :options="[
            $t('answers.yes'),
            $t('answers.no'),
            $t('answers.does_not_apply'),
          ]" v-model="answer" />
        </div>

        <div class="flex items-start space-x-2" v-else-if="
          preptoolQuestion.answer_type === 'calendar' &&
          (isEditing || !hasAnswered)
        ">
          <t-input type="datetime-local" class="form-input form-date" v-model="answer"
            v-if="preptoolQuestion.question_translation_key == 'preptool_question_two'"
            :min="$moment(event.date_from).format('YYYY-MM-DD[T]00:00')" />
          <t-input type="datetime-local" class="form-input form-date" v-model="answer" v-else />
        </div>

        <div class="flex items-center space-x-2" v-else-if="
          preptoolQuestion.answer_type === 'slider' &&
          (isEditing || !hasAnswered)
        ">
          <vue-slider class="flex-1" :min="0" :max="10" data-value="label" :label-style="{ display: 'none' }"
            :data="preptoolQuestion.answer_values" @change="handleChange" />
        </div>

        <div v-else>
          <p v-if="!answerIsOfTypeArray">
            {{
            $moment(answer).format("DD MMMM YYYY, HH:mm") === "Invalid date"
            ? answer
            : $moment(answer).format("DD MMMM YYYY, HH:mm")
            }}
          </p>
          <ul v-else>
            <li class="ml-8 list-disc" v-for="a in answer" :key="a">{{ a }}</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import IconInfo from "assets/icons/info-circle.svg";
import IconSave from "assets/icons/save.svg";
import IconPencil from "assets/icons/pencil.svg";
import VueSlider from "vue-slider-component";
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
import MyEventRepository from "../../../repositories/MyEventRepository";

const eventRepository = MyEventRepository;

export default {
  props: {
    preptoolQuestion: {
      type: Object,
    },

    disabled: {
      type: Boolean,
    },

    event: {
      type: Object,
    },
  },

  components: {
    IconInfo,
    IconSave,
    IconPencil,
    VueSlider,
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      infoOpen: false,
      answer: null,
      isEditing: false,
      hasAnswered: false,
    };
  },

  watch: {
    answer: {
      handler() {
        this.handleAnswerChange();
      },
    },
    loadedAnswers: {
      immediate: true,
      deep: true,
      handler() {
        if (this.loadedAnswers !== null) {
          this.answer =
            this.loadedAnswers[
              this.preptoolQuestion.question_translation_key
            ]?.answer;

          if (typeof this.answer !== "undefined") {
            this.hasAnswered = true;
          }
        }

        if (
          typeof this.answer === "undefined" &&
          this.preptoolQuestion.answer_type === "calendar"
        ) {
          this.answer = this.$moment(this.event.date_from).format(
            "DD/MM/YYYY HH:mm"
          );
        }
      },
    },
  },

  methods: {
    init() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.$el
          .querySelector("div")
          .classList.remove("border-brand-dark", "border");
        this.$refs.datepicker.$el.querySelector(
          "input[type='text']"
        ).style.display = "none";
      }
      // HOTFIX, BUDD-22
      /* if (this.preptoolQuestion.question_translation_key == 'preptool_question_two' && this.preptoolQuestion.type == 'calendar'){
        this.preptoolQuestion.attributes.min = moment().parse(this.event.date_from).format('YYYY-MM-DD[T]HH:mm');
      } */
    },

    handleChange(e) {
      this.answer = e;
    },

    onCheckClick() {
      this.submitAnswers();
      this.hasAnswered = true;
      this.isEditing = false;
    },

    onEditClick() {
      this.isEditing = true;
    },

    onInfoClick() {
      this.infoOpen = !this.infoOpen;
    },

    closeInfo() {
      this.infoOpen = false;
    },

    async submitAnswers() {
      await eventRepository.updatePreparationToolDataForEvent(
        this.event.uuid,
        this.answers
      );

      this.$gtm.trackEvent({
        category: "Preparation tool",
        action: "click",
        label: "User filled in preparation tool",
        value: `${this.user.uuid} has submitted an answer to his preparation tool`,
      });
    },

    async handleAnswerChange() {
      this.$store.commit("preptool/ADD_ANSWER", {
        question: this.preptoolQuestion.question_translation_key,
        question_after:
          this.answer === this.$t("answers.no") ||
          this.answer === this.$t("answers.does_not_apply")
            ? undefined
            : this.preptoolQuestion.question_after_translation_key,
        answer: this.answer,
      });
    },
  },

  computed: {
    ...mapGetters({
      user: "getProfile",
      answers: "preptool/answers",
      loadedAnswers: "preptool/loadedAnswers",
    }),

    answerIsOfTypeArray() {
      return this.answer instanceof Array;
    },
  },

  directives: {
    ClickOutside,
  },
};
</script>

<template>
  <wrapper>
    <portal to="topbar-left">
      <div
        @click="goBack"
        class="relative grid font-bold place-items-center"
        key="info-drugcombos-back"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 22" class="w-6 h-6">
          <g fill="currentColor" fill-rule="evenodd" transform="translate(-4 -1)">
            <path d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z" />
          </g>
        </svg>
      </div>
    </portal>
    <div class="mb-8">
      <h2 class="mb-2 text-2xl font-bold">
        {{ $t("preparation-tool.title") }}
      </h2>
      <div class="relative w-full max-w-screen-sm min-h-full p-4 mx-auto mt-6 text-sm leading-normal bg-white rounded-lg">
        <div class="max-w-full prose-sm prose break-words">
          <p>
            {{ $t("preparation-tool.intro", { eventName: this.event.name }) }}
          </p>
        </div>
      </div>
    </div>

    <div class="space-y-8">
      <loading v-if="loading" />
      <preptool-wrapper
        v-show="!loading"
        v-for="category in Object.keys(preptoolQuestions)"
        :category="category"
        :preptool-key="preptoolQuestions[category]"
        :key="category"
        :event="event"
      />
    </div>
  </wrapper>
</template>

<script>
import { mapGetters } from "vuex";

import PreptoolWrapper from "@/components/Event/Preptool/Wrapper";
import { preptoolQuestions } from "@/utils/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";

const eventRepository = RepositoryFactory.get("my-events");

export default {
  name: "PreparationTool",

  props: {
    event: {
      type: Object,
    },
  },

  components: {
    PreptoolWrapper,
  },

  mounted() {
    this.getPreparationToolDataForEvent();
  },

  destroyed() {
    this.$store.commit("preptool/REMOVE_ANSWERS");
  },

  data() {
    return {
      preptoolQuestions,
      loading: false,
    };
  },

  methods: {
    async getPreparationToolDataForEvent() {
      this.loading = true;
      const response = await eventRepository.getPreparationToolDataForEvent(this.event.uuid);

      this.$store.commit("preptool/ADD_ANSWERS", JSON.parse(response.data));
      this.loading = false;
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapGetters({
      isCheckedIn: "isCheckedIn",
    }),

    disabled() {
      if (!this.event.date_until) {
        return false;
      }
      return new Date(this.event.date_until).getTime() < Date.now();
    },
  },
};
</script>

<style></style>

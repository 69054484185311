<template>
  <div class="space-y-2">
    <h4 class="font-bold">
      {{ $t(`preparation-tool.questions.category.${category}`) }}
    </h4>
    <div class="w-full space-y-2">
      <preptool-item
        v-for="item in preptoolKey"
        :key="item.uuid"
        :preptool-question="item"
        :event="event"
        :disabled="false"
      />
    </div>
  </div>
</template>

<script>
import PreptoolItem from "./Item";

export default {
  components: {
    PreptoolItem
  },

  props: {
    preptoolKey: {
      type: Array
    },

    category: {
      type: String
    },

    event: {
      type: Object
    }
  },

  computed: {
    disabled() {
      const dateTimeFrom = new Date(this.event.date_from).getTime();
      const dateTimeUntil = new Date(this.event.date_until).getTime();
      const now = Date.now();
      if (!dateTimeUntil && dateTimeFrom < now) return true;

      return new Date(this.event.date_until).getTime() < Date.now();
    }
  }
};
</script>

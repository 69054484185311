<template>
  <div class="relative event-notebook">
    <portal to="topbar-left" v-if="event">
      <router-link
        :to="{ name: 'my-event', params: { uuid: event.uuid } }"
        class="font-bold"
        :class="{
          'text-white': isCheckedIn,
          'text-brand-dark': !isCheckedIn,
        }"
        key="event-notebook-back"
      >
        <icon-back class="w-6 h-6" />
      </router-link>
    </portal>

    <div class="flex flex-col w-full max-w-screen-sm min-h-full p-4 mx-auto space-y-4" v-if="event">
      <div class="flex items-center justify-between flex-1 space-y-1">
        <h2 class="text-xl font-bold">{{ $t("event.my-notes") }}</h2>

        <t-button variant="small" @click="openMoodModal">{{ $t("event.add_new_note") }}</t-button>
      </div>

      <div v-if="event_notes && event_notes.length" class="space-y-2">
        <list-item v-for="note in event_notes" :key="`event_${event.uuid}_note_${note.uuid}`" :multiline="true">
          <template v-slot:start>
            <svg
              viewBox="0 0 16 16"
              class="items-center flex-shrink-0 w-4 h-4 mr-2 font-black text-center text-brand-lightblue"
              v-if="note.type == 'checkin'"
            >
              <path
                d="M3.994,12.5a1.988,1.988,0,0,1-1.553-.781L.248,9.226a1,1,0,0,1,1.5-1.32l2.055,2.339a.25.25,0,0,0,.368.008l8.1-8.442a1,1,0,1,1,1.45,1.378L5.519,11.822A1.941,1.941,0,0,1,3.994,12.5Z"
                fill="currentColor"
                stroke="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
              ></path>
            </svg>
            <svg
              viewBox="0 0 16 16"
              class="items-center flex-shrink-0 w-4 h-4 mr-2 font-black text-center text-brand-lightblue"
              v-else-if="note.type == 'checkout'"
            >
              <path
                d="M8.591,7.177a.25.25,0,0,1,0-.354l4.616-4.616A1,1,0,1,0,11.793.793L7.177,5.409a.25.25,0,0,1-.354,0L2.207.793A1,1,0,0,0,.793,2.207L5.409,6.823a.25.25,0,0,1,0,.354L.793,11.793a1,1,0,0,0,1.414,1.414L6.823,8.591a.25.25,0,0,1,.354,0l4.616,4.616a1,1,0,0,0,1.414-1.414Z"
                fill="currentColor"
                stroke="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
              ></path>
            </svg>
            <svg
              viewBox="0 0 14 14"
              class="items-center flex-shrink-0 w-4 h-4 mr-2 font-black text-center text-brand-lightblue"
              v-else-if="note.type == 'after'"
            >
              <path
                d="M7,0a7,7,0,1,0,7,7A7.008,7.008,0,0,0,7,0ZM9.5,3.5a1,1,0,1,1-1,1A1,1,0,0,1,9.5,3.5Zm-5,0a1,1,0,1,1-1,1A1,1,0,0,1,4.5,3.5Zm6,6.625h-7a.625.625,0,0,1,0-1.25h7a.625.625,0,0,1,0,1.25Z"
                fill="currentColor"
                stroke="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
              ></path>
            </svg>
            <icon-event-note class="items-center flex-shrink-0 w-4 h-4 mr-2 font-black text-center text-brand-lightblue" v-else />
          </template>

          <template v-slot:default>
            <div class="flex flex-col self-center flex-1 space-y-2 font-sans font-weight text-brand-dark">
              <div>
                <h5 class="mb-1 font-bold leading-4">
                  {{ $d(new Date(note.created_at), "short") }} &middot;
                  {{ $d(new Date(note.created_at), "time") }}
                </h5>
                <span class="text-xs text-gray-400">
                  {{ $moment(note.created_at).from(Date.now()) }}
                </span>
              </div>
              <div v-text="note.content" v-if="!!note.content" class="text-sm break-words"></div>
            </div>
          </template>

          <template v-slot:end>
            <div class="flex-shrink-0 ml-auto leading-4" v-if="!!note.mood">
              <mood-icon :value="note.mood" class="w-10 h-10" :shadowColor="`#DDDDDD`" />
            </div>
            <div v-else class="w-10 h-10 opacity-0"></div>
          </template>
        </list-item>
      </div>
      <div class="text-sm" v-else>{{ $t("event.empty--notes") }}</div>
    </div>

    <popup-pane v-if="eventMoodModalOpen" :title="$t('event.notebook_popup_title')" :dark="isCheckedIn" @close="eventMoodModalOpen = false">
      <form @submit.prevent="submitNote">
        <t-input-group>
          <t-textarea :placeholder="$t('modals.daily_mood.placeholder')" v-model="form.note" />
        </t-input-group>

        <t-button type="submit" :disabled="form_loading">{{ $t("action.save") }}</t-button>
      </form>
    </popup-pane>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Form } from "vform";
import MoodIcon from "components/MoodIcon.vue";
import IconEventNote from "assets/icons/event-notes.svg";
import ListItem from "components/ListItem.vue";
import IconBack from "assets/icons/back.svg";
import Repository from "repositories/RepositoryFactory";
// import Modal from "components/Modal";
import PopupPane from "../../components/PopupPane";

const EventRepository = Repository.get("my-events");

export default {
  name: "event-single-notebook",

  components: {
    PopupPane,
    MoodIcon,
    IconEventNote,
    ListItem,
    // Modal,
    IconBack,
  },

  props: {
    event: {
      type: Object,
      default: undefined,
    },
  },

  mounted() {
    console.log(this.$d);
  },

  data: () => ({
    form: new Form({
      note: "",
    }),
    eventMoodModalOpen: false,
    form_loading: false,
    form_error: "",
  }),

  computed: {
    event_notes() {
      return this.event.log || [];
    },
    ...mapGetters(["currentEvent", "isCheckedIn"]),
  },

  methods: {
    openMoodModal() {
      this.eventMoodModalOpen = true;
    },

    onMoodChange(mood) {
      this.form.mood = mood;
    },

    submitNote() {
      this.form_loading = true;
      const { note } = this.form;
      EventRepository.addNote(this.event.uuid, note)
        .then(() => {
          this.$emit("event-updated");
          this.form.reset();
          this.form.clear();
        })
        .catch((err) => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.form.errors = [this.$i18n.t("unknown_error")];
        })
        .then(() => {
          this.form_loading = false;
          this.eventMoodModalOpen = false;
        });
    },
  },
  metaInfo() {
    return {
      title: `${this.event ? this.event.name : "Loading..."} - ${this.$i18n.t("event.notebook")}`,
    };
  },
};
</script>

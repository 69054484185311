<template>
  <div
    class="focus:outline-none focus:shadow-outline-gray flex w-full min-w-0 flex-row flex-wrap items-center justify-between justify-items-stretch space-x-1 rounded-xl bg-white px-1.5 py-2.5 text-brand-dark shadow 2xs:space-x-2 2xs:px-4 2xs:py-4">
    <div class="flex w-auto min-w-0 flex-shrink-0 items-center justify-center" v-if="$slots.start">
      <slot name="start" />
    </div>
    <div class="w-0 flex-1 flex-wrap">
      <div class="overflow-hidden text-left 2xs:text-sm sm:text-base" :class="{ truncate: !multiline }">
        <slot />
      </div>
    </div>
    <div class="flex w-auto flex-shrink-0 items-end justify-center self-center justify-self-end">
      <slot name="end">
        <icon-forward class="h-6 w-6 text-brand-dark" />
      </slot>
    </div>
  </div>
</template>

<script>
import IconForward from "assets/icons/forward.svg";

export default {
  name: "ListItem",
  components: {
    IconForward,
  },
  props: {
    multiline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
